<header>
  <div class="row">
    <!-- <div class="col-md-1"></div> -->
    <div class="col-4">
      <img src="./assets/logo-h.png" alt="Smart Selangor" class="logo" srcset="" (click)="goToHomePage()">
    </div>
    <!-- <div class="col-md-4 col-xs-0"></div> -->
    <div class="col-8 col-md-8 mt-2 text-right">
      <button class="btn mr-2 login" *ngIf="userName">
        <em class="fa fa-user-circle mr-2"></em>
        {{userName}}
      </button>
      <button class="btn mr-2 login" (click)="goToLogin()" *ngIf="!userName">
        <em class="fa fa-user-circle mr-2"></em>
        Log In
      </button>
      <div ngbDropdown class="d-inline-block" placement="bottom-right">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
          <em class="fa fa-bars"></em>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button ngbDropdownItem (click)="goToAddListing()">Add Listing</button>
          <button ngbDropdownItem (click)="goToviewMyListings()">View My Listings</button>
          <button ngbDropdownItem (click)="logout()" *ngIf='userName'>Logout</button>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="col-12 text-center">
  <div class="spinner-border text-primary mt4" *ngIf="isLoading"></div>
</div>
<router-outlet *ngIf="!isLoading"></router-outlet>

<footer>© 2020 by SSDU Innovations Sdn Bhd</footer>
