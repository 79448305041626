import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseURL: string;
  private _visitorCount = new BehaviorSubject(null);
  private _login = new BehaviorSubject(null);

  getvisitorCount() {
    return this._visitorCount.asObservable();
  }

  setvisitorCount(count) {
    this._visitorCount.next(count);
  }

  getLogin() {
    return this._login.asObservable();
  }

  setLogin(user) {
    this._login.next(user);
  }

  constructor(private readonly http: HttpClient) {
    this.baseURL = environment.baseURL;
   }

  getDistricts() {
    return this.http
      .get(`${this.baseURL}/sme/getDistrict`, httpOptions)
      .pipe(catchError(this.handleError('getDistricts()', [])));
  }

  incrementAndGetVisitorCount() {
    return this.http
      .get(`${this.baseURL}/sme/getVisitorCount`, httpOptions)
      .pipe(catchError(this.handleError('getVisitorCount()', [])));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const pError = JSON.parse(error._body);
      console.error('handle error', error); // log to console instead
      if (error.status === 500 && pError.message === 'JWT Token is incorrect') {
        // this.auth.logout();
      }
      this.log(`${operation} failed: ${error.status}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(errMsg) {
    console.log(errMsg);
  }
}
