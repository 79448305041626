import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { Observable, of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ViewListingService {
  baseURL: string;

  constructor(private readonly http: HttpClient) {
    this.baseURL = environment.baseURL;
   }

   searchBusinessByText(body) {
    return this.http
      .post(`${this.baseURL}/sme/searchByText`, body, httpOptions)
      .pipe(catchError(this.handleError('searchBusinessByText()', [])));
  }

  searchBusinessByCategory(body) {
    return this.http
      .post(`${this.baseURL}/sme/searchByCategory`, body, httpOptions)
      .pipe(catchError(this.handleError('searchBusinessByText()', [])));
  }

  getCategoriesAndBusinessCount() {
    return this.http
      .get(`${this.baseURL}/sme/getCategoriesAndBusinessCount`, httpOptions)
      .pipe(catchError(this.handleError('searchBusinessByText()', [])));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const pError = JSON.parse(error._body);
      console.error('handle error', error); // log to console instead
      if (error.status === 500 && pError.message === 'JWT Token is incorrect') {
        // this.auth.logout();
      }
      this.log(`${operation} failed: ${error.status}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  log(errMsg) {
    console.log(errMsg);
  }
}
