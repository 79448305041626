<div class="modal-header">
  <h6 class="modal-title" id="modal-title" *ngIf="data.type == 'rate'">
    Rating by {{ '"user_name"' }}
  </h6>

  <div class="col-11 rate" *ngIf="data.type == 'details'">
    <div class="business-name text-primary">{{data.business.businessName}}
      <!-- <span class="ssm"> {{data.business.ssmNo ? '(' +data.business.ssmNo+ ')' : ''}}</span> -->
    </div>
    <div class="col-12 address px-0">{{ data.business.address }}</div>
  </div>
  <!-- <h6 class="modal-title" id="modal-title" *ngIf="data.type == 'details'">
    Update Commission Rule
  </h6> -->
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row rate" *ngIf="data.type === 'rate'">
    <div class="col-3 pr-0">
      <div class="logo" [style.background-image]="'url(' + data.business.logoUrl + ')'"></div>
    </div>
    <div class="col-9">
      <div class="business-name text-primary">{{data.business.businessName}}
        <span class="ssm"> {{data.business.ssmNo ? '(' +data.business.ssmNo+ ')' : ''}}</span>
      </div>
      <div class="col-12 address px-0">{{ data.business.address }}</div>
      <div class="">
        <ngb-rating [(rate)]="rating" [max]="5"></ngb-rating>
      </div>
    </div>
  </div>

  <div class="row details" *ngIf="data.type === 'details'">

    <!-- <div class="col-12 rate mb-4 ml-2" *ngIf="data.type == 'details'">
      <div class="business-name text-primary">{{data.business.businessName}}
        <span class="ssm"> {{data.business.ssmNo ? '(' +data.business.ssmNo+ ')' : ''}}</span>
      </div>
      <div class="col-12 address px-0">{{ data.business.address }}</div>
    </div> -->

    <div class="col-4 col-sm-3">
      <div class="row rating-point ml-0 mb-3">{{data.business.rating || '3.5'}}</div>
      <div class="row ml-3 mb-1">
        <ng-template #t let-fill="fill">
          <span class="star" [class.full]="fill === 100">
            <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
          </span>
        </ng-template>

        <ngb-rating [rate]="3.5" [starTemplate]="t" [readonly]="true" [max]="5"></ngb-rating>
      </div>
      <div class="row ml-4 text-center">
        <em class="fa fa-user mr-1"></em>
        <span class="rating-count">
          {{(data.business.ratingCount || '328693') | number}}
        </span>
      </div>

    </div>
    <div class="col-8 col-sm-9">
      <div class="row">
        <div class="col-2 col-sm-2 mb-2 pr-0">5 &#9733; </div>
        <ngb-progressbar class="col-8 col-sm-9 pl-0" type="success" [value]="40"></ngb-progressbar>
        <div class="col-1 px-0">40%</div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-2 mb-2 pr-0">4 &#9733;</div>
        <ngb-progressbar class="col-8 col-sm-9 pl-0" type="green" [value]="20"></ngb-progressbar>
        <div class="col-1 px-0">20%</div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-2 mb-2 pr-0">3 &#9733;</div>
        <ngb-progressbar class="col-8 col-sm-9 pl-0" type="yellow" [value]="15"></ngb-progressbar>
        <div class="col-1 px-0">15%</div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-2 mb-2 pr-0">2 &#9733;</div>
        <ngb-progressbar class="col-8 col-sm-9 pl-0" type="orange" [value]="5"></ngb-progressbar>
        <div class="col-1 px-0">5%</div>
      </div>
      <div class="row">
        <div class="col-2 col-sm-2 mb-2 pr-0">1 &#9733;</div>
        <ngb-progressbar class="col-8 col-sm-9 pl-0" type="danger" [value]="20"></ngb-progressbar>
        <div class="col-1 px-0">20%</div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <!-- {{(commissionRuleForm.transactionFeeMYR || commissionRuleForm.transactionFeePercent) ? !nrf.valid : true}} -->
  <button
    *ngIf="data.type == 'rate'"
    type="button"
    class="btn btn-outline-success"
    [disabled]="!rating"
  >
    Submit
  </button>
  <button
    *ngIf="data.type == 'edit'"
    type="button"
    class="btn btn-outline-success"
  >
    Update
  </button>
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('cancel click')"
  >
    {{ data.type === 'rate' ? 'Cancel' : 'Close'}}
  </button>
</div>
