import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SwalPopupService {
  constructor() {}

  error() {
    Swal.fire({
      title: 'Oops...',
      icon: 'error',
      text: 'Something went wrong',
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#00bbff',
      allowOutsideClick: false,
    });
  }

  customError(title, icon, text) {
    Swal.fire({
      title: title,
      icon: icon,
      text: text,
      showCloseButton: false,
      showCancelButton: false,
      focusConfirm: true,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#00bbff',
      allowOutsideClick: false,
    });
  }
}
